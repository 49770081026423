var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',[(_vm.Notmodal)?_c('LogsNoti',{attrs:{"statusnoti":_vm.itemsNot},on:{"principal":_vm.fecharModal}}):_vm._e(),(!_vm.Notmodal)?_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',[_vm._v(" fas fa-comments ")]),_c('v-toolbar-title',{staticClass:"pl-4"},[_vm._v(" Status Notificações ")]),_c('v-spacer'),_c('v-app-bar-nav-icon',{on:{"click":_vm.fechar}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{staticClass:"elevation-1 pt-4",attrs:{"dense":"","headers":_vm.grid.status.cabecalho,"items":_vm.grid.status.items,"items-per-page":_vm.grid.status.porPagina,"hide-default-footer":"","loading":_vm.grid.status.carregando},on:{"click:row":_vm.carregarDetalhes},scopedSlots:_vm._u([_vm._l((_vm.grid.status.cabecalho.filter(function (header) { return header.hasOwnProperty('formatter'); }
              )),function(header){return {key:("item." + (header.value)),fn:function(ref){
              var header = ref.header;
              var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.notificado",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-app-bar-nav-icon',_vm._g({},on),[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"center"}},[_c('td',[_c('v-icon',{attrs:{"size":"16","color":_vm.getColor(item.notificado)}},[_vm._v("fa-solid fa-circle")])],1)])])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.getStatus(item.notificado))+" ")])])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}}),_c('v-col',{staticStyle:{"padding-bottom":"0px","padding-top":"0px"},attrs:{"cols":"6"}},[_c('b',[_vm._v("Legenda")])]),_c('v-col',{attrs:{"cols":"3"}})],1),_c('v-row',[_c('v-col',{staticStyle:{"padding-bottom":"0px","padding-top":"0px"},attrs:{"cols":"10"}},[_vm._v(" Notificado com Sucesso ")]),_c('v-col',{staticStyle:{"padding-bottom":"0px","padding-top":"0px"},attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"size":"16","color":_vm.getColor(1)}},[_vm._v("fa-solid fa-circle")])],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"padding-bottom":"0px","padding-top":"0px"},attrs:{"cols":"10"}},[_vm._v(" Notificado com Erro na Resposta ")]),_c('v-col',{staticStyle:{"padding-bottom":"0px","padding-top":"0px"},attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"size":"16","color":_vm.getColor(2)}},[_vm._v("fa-solid fa-circle")])],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"padding-bottom":"0px","padding-top":"0px"},attrs:{"cols":"10"}},[_vm._v(" Não Notificado ")]),_c('v-col',{staticStyle:{"padding-bottom":"0px","padding-top":"0px"},attrs:{"cols":"2"}},[_c('v-icon',{attrs:{"size":"16","color":_vm.getColor(0)}},[_vm._v("fa-solid fa-circle")])],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }