<template>
  <article>

    <!-- Detalhes Notify -->
		<LogsNoti v-if="Notmodal" v-on:principal="fecharModal" :statusnoti="itemsNot" />
    <!-- Fim  -->

    <!-- Início principal -->
    <v-card v-if="!Notmodal">
      <v-toolbar flat>
        <v-icon> fas fa-comments </v-icon>
        <v-toolbar-title class="pl-4"> Status Notificações </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="fechar">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="3"> </v-col>
          <v-col cols="6">
            <v-data-table
              dense
              :headers="grid.status.cabecalho"
              :items="grid.status.items"
              :items-per-page="grid.status.porPagina"
              hide-default-footer class="elevation-1 pt-4"
              :loading="grid.status.carregando"
              @click:row="carregarDetalhes"
            >
              <!-- This template looks for headers with formatters and executes them -->
              <template v-for="header in grid.status.cabecalho.filter((header) =>
                header.hasOwnProperty('formatter')
              )" v-slot:[`item.${header.value}`]="{ header, value }">
                {{ header.formatter(value) }}
              </template>
              <!-- Inicio Detalhamento -->
              <template #item.notificado="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-app-bar-nav-icon v-on="on">
                      <div style="display: flex; align-items: center; justify-content: center;">
                        <td>
                          <v-icon size="16" :color="getColor(item.notificado)">fa-solid fa-circle</v-icon>
                        </td>
                      </div>
                    </v-app-bar-nav-icon>
                  </template>
                  <span>
                    {{ getStatus(item.notificado) }}
                    <!-- <template v-if="item.notificado == 1">
                      {{ Sim }}
                    </template>
                    <template v-else-if="item.notificado == 2">
                      {{ "Sim, retornando Erro" }}
                    </template>
                    <template v-else>
                      {{ "Não" }}
                    </template> -->
                  </span>
                </v-tooltip>
              </template>
              <!-- Fim Detalhamento -->
            </v-data-table>
          </v-col>

          <v-col cols="3">
            <v-card>
              <v-card-text>
                <v-row>
                  <v-col cols="3"> </v-col>
                  <v-col cols="6" style="padding-bottom: 0px; padding-top: 0px">
                    <b>Legenda</b>
                  </v-col>
                  <v-col cols="3"> </v-col>
                </v-row>

                <v-row>
                  <v-col cols="10" style="padding-bottom: 0px; padding-top: 0px">
                    Notificado com Sucesso
                  </v-col>
                  <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
                    <v-icon size="16" :color="getColor(1)">fa-solid fa-circle</v-icon>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="10" style="padding-bottom: 0px; padding-top: 0px">
                    Notificado com Erro na Resposta
                  </v-col>
                  <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
                    <v-icon size="16" :color="getColor(2)">fa-solid fa-circle</v-icon>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="10" style="padding-bottom: 0px; padding-top: 0px">
                    Não Notificado
                  </v-col>
                  <v-col cols="2" style="padding-bottom: 0px; padding-top: 0px">
                    <v-icon size="16" :color="getColor(0)">fa-solid fa-circle</v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Fim principal -->

  </article>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../components/Template.vue";
import LogsNoti from "../components/logs/frmLogNoti.vue";

export default {
  name: 'StatusNotificacoes',
  components: {
    Template,
    LogsNoti,
  },
  data: () => ({
    grid: {
      status: {
        cabecalho: [
          { text: "Canal", value: "who_notifi", sortable: false },
          { text: "Periodo", value: "Periodo", sortable: false, formatter: (x) => x ? moment(x).format("MM/yyyy") : null, },
          { text: "Status", value: "notificado", sortable: false },
          { text: "Quantidade", value: "Total", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        porPagina: 100,
        qtdsPaginacao: [10, 15, 50, 100],
        itemSelecionado: [],
        coluna: "codigo",
        direcao: "desc",
      },
    },
    itemsNot: {},
    Notmodal: false,
    principal: true,

  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
    await this.carregarStatus();
  },
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
    },
    fechar() {
      this.$router.push("/");
    },
    fecharModal() {
      this.Notmodal = false;
      this.carregarStatus();
    },
    getColor(color) {
      if (color == 1) {
        return "#3CA40B"; // verde
      } else if (color == 2) {
        return "#FF0000"; // vermelho
      } else {
        return "#FAB913"; // laranja
      }
    },
    getStatus(status) {
      if (status == 1) {
        return "Notificado com sucesso";
      } else if (status == 2) {
        return "Notificado com Erro";
      } else {
        return "Não Notificado"; // laranja
      }
    },
    async carregarStatus() {
      this.grid.status.carregando = true;
      let url = `${baseApiUrl}/status-notifications?cod_empresa=${this.codigo_empresa}`;

      await axios
        .get(url)
        .then((res) => {
          if (res.data.retorno) {
            this.grid.status.items = res.data.dados;
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => (this.grid.status.carregando = false));
    },
    carregarDetalhes(itemRow) {
      itemRow.status = this.getStatus(itemRow.notificado);
      this.itemsNot = itemRow;
      this.Notmodal = true;
      this.principal = false
    },
  },
}
</script>

<style lang="scss" scoped></style>