<template>
  <article>

  	<!-- Início detalhes -->
		<Detalhes v-if="detmodal" v-on:detclose="fecharDetalhes" :consulta="objeto" />
  	<!-- Fim detalhes -->

    <!-- inicio modal requisicao  -->
    <!-- width="1900px" -->
    <v-dialog
      v-model="modalRetorno"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      style="background-color: white;"
    >
      <v-card>
        <v-toolbar color="#018656" dark dense :style="style">
          <v-toolbar-title class="pl-4">
            Retorno Objeto {{ json_Retorno.obj }} Pedido {{ json_Retorno.ped }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="fecharRetorno()">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-toolbar>

        <!-- <v-card-title color="#018656" dark dense >
          Retorno Objeto {{ json_Retorno.obj }} Pedido {{ json_Retorno.ped }}
          <v-spacer></v-spacer>
          <v-app-bar-nav-icon @click="fecharRetorno()">
            <v-icon>fas fa-times</v-icon>
          </v-app-bar-nav-icon>
        </v-card-title> -->

        <v-card-text style="overflow: hidden; word-break: break-word;">
          <pre style="margin-top: 5px !important">
            {{ json_Retorno.json }}
          </pre>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#018656" text @click="fecharRetorno()">
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Início principal -->
    <v-card>
      <v-toolbar flat>
        <v-icon> fas fa-comments </v-icon>
        <v-toolbar-title class="pl-4"> Logs Notificações {{ this.headerlog }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-app-bar-nav-icon @click="$emit('principal')">
          <v-icon>fas fa-times</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>

        <!-- inicio data table -->
        <v-row>
          <v-col class="px-6 py-6">
            <v-data-table
              dense
              :headers="grid.logs.cabecalho"
              :items="grid.logs.items"
              :items-per-page="grid.logs.porPagina"
              hide-default-footer class="elevation-1 pt-4"
              :loading="grid.logs.carregando"
            >
              <v-progress-linear v-show="grid.logs.carregando" slot="progress" color="#018656" indeterminate></v-progress-linear>
              <template #item.numero_rastreio="{ item }">
                <button type="button" style="font-weight: bold; color: #018656;" @click="() => carregarDetalhes(item)">
                  {{ item.numero_rastreio }}
                </button>
              </template>

              <template #item.ret_notify="{ item }">
                <button v-if="item.notificado == 0" type="text" style="font-weight: bold; color: #FAB913;">
                  {{ `Não Notificado` }}
                </button>
                <button v-else-if="item.notificado == 1" type="button" style="font-weight: bold; color: #018656;" @click="() => carregarRetorno(item)">
                  {{ `Sucesso` }}
                </button>
                <button v-else-if="item.notificado == 2" type="button" style="font-weight: bold; color: #FF0000;" @click="() => carregarRetorno(item)">
                  {{ `Notificado com Erro` }}
                </button>
              </template>

              <!-- This template looks for headers with formatters and executes them -->
              <template v-for="header in grid.logs.cabecalho.filter((header) =>
                header.hasOwnProperty('formatter')
              )" v-slot:[`item.${header.value}`]="{ header, value }">
                {{ header.formatter(value) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <!-- final data table -->

        <!-- Inicio Paginacao -->
        <v-row class="mt-4">
          <v-col cols="2">
            <v-btn fab small color="primary" dark @click="voltarTopo()" style="margin-left: 10px">
              <v-icon> fas fa-arrow-up </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8">
            <v-pagination v-model="grid.logs.paginaAtual"
              :length="Math.ceil(grid.logs.totalRegistros / this.grid.logs.porPagina)" @input="mudarPagina"
              :total-visible="5"></v-pagination>
          </v-col>
          <v-col cols="2"> </v-col>
        </v-row>
        <!-- Final Paginacao -->

      </v-card-text>
    </v-card>
    <!-- final Principal -->
  </article>
</template>

<script>
import { baseApiUrl } from "@/global";
import axios from "axios";
import moment from "moment";
import Template from "../Template.vue";
import Detalhes from "../objetos/frmdetalhes.vue";

export default {
  name: "LogsNoti",
  components: {
    Template,
    Detalhes
  },
  props: {
    statusnoti: { type: Object },
  },
  data: () => ({
    grid: {
      logs: {
        cabecalho: [
          { text: "#", value: "idnotificacoes", sortable: false },
          { text: "Rastreio", value: "numero_rastreio", sortable: false },
          { text: "Ordem Rastreio", value: "id_rastreio", sortable: false },
          { text: "Numero Pedido", value: "pedido_canal_venda", sortable: false },
          { text: "Canal", value: "who_notifi", sortable: false },
          { text: "Status", value: "notificado", sortable: false },
          { text: "Data Status", value: "data_notificado", sortable: false, formatter: (x) => x ? moment(x).format("DD/MM/yyyy HH:mm:ss") : null, },
          { text: "Retorno Status", value: "ret_notify", sortable: false },
        ],
        items: [],
        carregando: false,
        paginaAtual: 1,
        totalRegistros: 0,
        porPagina: 100,
        qtdsPaginacao: [10, 15, 50, 100],
        itemSelecionado: [],
        coluna: "codigo",
        direcao: "desc",
      },
    },
    objeto: {
      numero_rastreio: "",
      rastreio: "",
      protocolo_reclamacao: "",
      item: {},
      detalhes: false,
      habilita: false,
      aba_rastreio: true,
    },
    itensFiltro: [],
    headerlog: "",
    detmodal: false,
    modalRetorno: false,
    json_Retorno: {
      obj: '',
      ped: '',
      json: [],
    },

  }),
  created() {
    this.codigo_empresa = Number(this.$store.state.cod_empresa);
  },
  async mounted() {
    let periodo = this.statusnoti.Periodo ? moment(this.statusnoti.Periodo).format("MM/yyyy") : "";
    periodo = periodo ? `Periodo - ${periodo}` : "";
    this.headerlog = `${this.statusnoti.status} Canal - ${this.statusnoti.who_notifi} ${periodo} Qtde - ${this.statusnoti.Total}`;
    await this.carregarLogs(this.statusnoti);
  },
  computed: {
  },
  methods: {
    limparFiltros() {
      this.filtros.codigo = "";
    },
    fechar() {
      this.$router.push("/");
    },
    getColor(color) {
      if (color == 1) {
        return "#018656"; // verde
      } else if (color == 2) {
        return "#FF0000"; // vermelho
      } else {
        return "#FAB913"; // laranja
      }
    },
    carregarRetorno(itemRet) {
      this.json_Retorno.obj = itemRet.numero_rastreio;
      this.json_Retorno.ped = itemRet.pedido_canal_venda;
      this.json_Retorno.json = JSON.parse(itemRet.ret_notify);
      this.modalRetorno = true;
    },
    fecharRetorno() {
      this.modalRetorno = false;
      this.json_Retorno.obj = '';
      this.json_Retorno.ped = '';
      this.json_Retorno.json = [];
    },

    async fecharDetalhes(Atualiza) {
      this.detmodal = false;
      this.objeto = {
        item: {},
        detalhes: false,
        habilita: false,
      }
      if (Atualiza)
      {
        // await this.carregarLogs(this.statusnoti);
      }
    },
    async carregarDetalhes(item) {
      this.objeto.item = item;
      this.objeto.numero_rastreio = item.numero_rastreio;
      this.objeto.item.cod_empresa = this.codigo_empresa;
      this.objeto.habilita = false;
      this.objeto.aba_rastreio = true,
      this.objeto.detalhes = true;
      this.detmodal = true;
    },
    async filtroLogs() {
      await this.carregarLogs(this.statusnoti);
    },
    async carregarLogs(item) {

      this.grid.logs.carregando = true;

      let periodo = item.Periodo ? item.Periodo : 0;
      let url = `${baseApiUrl}/logs-notifications?cod_empresa=${this.codigo_empresa}`;
      url += `&status=${item.notificado}`;
      url += `&who=${item.who_notifi}`;
      url += `&val=${periodo}`;

      await axios
        .get(url)
        .then((res) => {
          if (res.data.retorno) {
            this.grid.logs.items = res.data.dados.data;
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => (this.grid.logs.carregando = false));
    },
    mudarPagina(pagina) {
      this.grid.logs.paginaAtual = pagina;
      // this.carregarLogs(this.statusnoti);
    },
  },
  watch: {
    "grid.logs.porPagina": {
      handler: function (val, oldVal) {
        if (val != oldVal) {
          if (val > 0) {
            this.grid.logs.porPagina = Number(val);
            this.mudarPagina(1);
          } else {
            this.grid.logs.porPagina = 100;
            this.mudarPagina(1);
          }
        }
      },
    },
  },
}
</script>

<style scoped>
.active {
  background-color: #018656;
  color: #fff;
}
</style>